<template>
    <div class="get-inspired">
        <div v-html="model"></div>
    </div>
</template>

<script>
    export default {
        name: "GetInspired",
        props: ['model']
    }
</script>
