<template>
  <div class="donate-form">
    <b-form @submit.stop.prevent="submit">
      <b-row align-h="center">
        <b-col md="10" cols="12">
          <b-row align-v="top" class="mb-3">
            <b-col cols="auto" class="mr-auto">
              <h5 class="title--section color--pepper">
                {{ model.translations["donation"] }}
              </h5>
            </b-col>
            <b-col cols="auto">
              <p class="donate-form--smaller-text">
                {{
                  model.translations["donation.Form.MandatoryFieldsExplanation"]
                }}
              </p>
            </b-col>
          </b-row>
          <b-row v-if="!hideDonationTypes">
            <b-col cols="12">
              <b-form-select
                name="donationType"
                v-model="$v.state.donationType.$model"
                :options="model.donationTypes"
                :state="validationState($v.state.donationType)"
                @change="changeType($event)"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >{{
                      model.translations["donation.Form.ReferenceEventType"]
                    }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                v-if="
                  $v.state.donationType.$dirty &&
                    !$v.state.donationType.required
                "
                >{{ model.translations["generic.SelectAtLeastOneOption"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              order-lg="1"
              v-if="
                model.predefinedDonationValues &&
                  model.predefinedDonationValues.length
              "
              lg="6"
              cols="12"
            >
              <div class="donate-form__predefined-donations">
                <b-row align-h="center">
                  <b-col
                    class="donate-form__predefined-donations__item"
                    cols="6"
                    sm="3"
                    @click="setAmount(item)"
                    :key="index"
                    v-for="(item, index) in model.predefinedDonationValues"
                  >
                    <span class="btn btn-success">&pound; {{ item }}</span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              :lg="
                model.predefinedDonationValues &&
                model.predefinedDonationValues.length
                  ? 6
                  : 12
              "
              cols="12"
            >
              <currency-input
                class="w-100 form-control"
                name="amount"
                :class="{
                  'is-invalid':
                    $v.state.amount.$invalid && $v.state.amount.$dirty
                }"
                v-model="$v.state.amount.$model"
                currency="GBP"
                locale="en-GB"
                :placeholder="model.translations['donation.Form.Amount']"
                :allow-negative="false"
                :precision="2"
                :value-range="{ min: 0, max: 9999 }"
              ></currency-input>
              <b-form-invalid-feedback
                v-if="$v.state.amount.$dirty && !$v.state.amount.required"
                >{{ model.translations["generic.FieldRequired"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row v-if="showHospice">
            <b-col cols="12">
              <b-form-select
                name="hospice"
                v-model="$v.state.hospiceId.$model"
                :options="model.hospiceList"
                :state="validationState($v.state.hospiceId)"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >{{ model.translations["donation.Form.SelectHospice"] }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                v-if="$v.state.hospiceId.$dirty && !$v.state.hospiceId.required"
                >{{ model.translations["generic.SelectAtLeastOneOption"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-textarea
                name="message"
                v-model="state.message"
                rows="3"
                :placeholder="
                  model.translations['donation.Form.OptionalMessage']
                "
              ></b-form-textarea>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12">
              <h5 class="title--section color--pepper">
                {{ model.translations["donation.Form.ContactDetails"] }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="2">
              <b-form-select
                name="title"
                v-model="$v.state.title.$model"
                :options="model.titles"
                :state="validationState($v.state.title)"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >{{ model.translations["generic.PersonalDetails.Title"] }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                v-if="$v.state.title.$dirty && !$v.state.title.required"
                >{{ model.translations["generic.SelectAtLeastOneOption"] }}
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-input
                name="firstName"
                v-model="$v.state.firstName.$model"
                :placeholder="
                  model.translations['generic.PersonalDetails.FirstName']
                "
                :max="maxNameLength"
                :state="validationState($v.state.firstName)"
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="$v.state.firstName.$dirty && !$v.state.firstName.required"
                >{{ model.translations["generic.FieldRequired"] }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="
                  $v.state.firstName.$dirty && !$v.state.firstName.maxLength
                "
                >{{ model.translations["generic.ValueTooLong"] }}
                {{ maxNameLength }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="
                  $v.state.firstName.$dirty && !$v.state.firstName.nameField
                "
                >{{ model.translations["generic.IllegalCharacters"] }}
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-input
                name="lastName"
                v-model="$v.state.lastName.$model"
                :placeholder="
                  model.translations['generic.PersonalDetails.LastName']
                "
                :max="maxNameLength"
                :state="validationState($v.state.lastName)"
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="$v.state.lastName.$dirty && !$v.state.lastName.required"
                >{{ model.translations["generic.FieldRequired"] }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="$v.state.lastName.$dirty && !$v.state.lastName.maxLength"
                >{{ model.translations["generic.ValueTooLong"] }}
                {{ maxNameLength }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="$v.state.lastName.$dirty && !$v.state.lastName.nameField"
                >{{ model.translations["generic.IllegalCharacters"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <vue-tel-input
                name="phone"
                v-model="$v.state.phone.$model"
                :placeholder="
                  model.translations['generic.PersonalDetails.PhoneNumber']
                "
                :wrapper-classes="{
                  'is-invalid': validationState($v.state.phone) === false,
                  'custom-phone': true
                }"
              ></vue-tel-input>
              <b-form-invalid-feedback
                v-if="$v.state.phone.$dirty && !$v.state.phone.required"
                >{{ model.translations["generic.FieldRequired"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-input
                name="email"
                type="email"
                v-model="$v.state.email.$model"
                :placeholder="
                  model.translations['generic.PersonalDetails.Email']
                "
                :state="
                  validateEmail($v.state.email, $v.state.emailConfirmation)
                "
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="$v.state.email.$dirty && !$v.state.email.required"
                >{{ model.translations["generic.FieldRequired"] }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="$v.state.email.$dirty && !$v.state.email.email"
                >{{ model.translations["generic.FieldEmailInvalid"] }}
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-input
                name="repeatEmail"
                type="email"
                v-model="$v.state.emailConfirmation.$model"
                :placeholder="
                  model.translations[
                    'generic.PersonalDetails.EmailConfirmation'
                  ]
                "
                :state="validationState($v.state.emailConfirmation)"
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="
                  $v.state.emailConfirmation.$dirty &&
                    !$v.state.emailConfirmation.required
                "
                >{{ model.translations["generic.FieldRequired"] }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="
                  $v.state.emailConfirmation.$dirty &&
                    !$v.state.emailConfirmation.sameAsEmail
                "
                >{{
                  model.translations["generic.PersonalDetails.EmailsNotMatch"]
                }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12">
              <h5 class="title--section color--pepper">Address</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input
                name="addressLine1"
                v-model="$v.state.addressLine1.$model"
                :state="validationState($v.state.addressLine1)"
                :placeholder="
                  model.translations['generic.Address.AddressLine1']
                "
              ></b-form-input>
              <b-form-invalid-feedback
                :state="validationState($v.state.addressLine1)"
                >{{ model.translations["generic.FieldRequired"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input
                name="addressLine2"
                v-model="state.addressLine2"
                :placeholder="
                  model.translations['generic.Address.AddressLine2']
                "
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input
                name="city"
                v-model="$v.state.locality.$model"
                :state="validationState($v.state.locality)"
                :placeholder="model.translations['generic.Address.Locality']"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="validationState($v.state.locality)"
                >{{ model.translations["generic.FieldRequired"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-select
                name="countryId"
                v-model="$v.state.countryId.$model"
                :options="model.countries"
                :state="validationState($v.state.countryId)"
                @change="changeCountry($event)"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >{{ model.translations["generic.Address.Country"] }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                v-if="$v.state.countryId.$dirty && !$v.state.countryId.required"
                >{{ model.translations["generic.SelectAtLeastOneOption"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input
                name="postCode"
                type="text"
                v-model.trim="state.postCode"
                :placeholder="model.translations['generic.Address.Postcode']"
                :state="validationState($v.state.postCode)"
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="$v.state.postCode.$dirty && !$v.state.postCode.required"
                >{{ model.translations["generic.FieldRequired"] }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="$v.state.postCode.$dirty && !$v.state.postCode.isCorrect"
                >{{ model.translations["donation.Form.InvalidPostCode"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <hr v-if="showGiftAid" />
          <b-row v-if="showGiftAid" align-h="center">
            <b-col
              cols="12"
              md="11"
              class="shadow-box padding--text-content--small"
            >
              <div class="donate-form__gift-aid">
                <h5 class="title--list color--pepper">
                  {{ model.giftAid.title }}
                </h5>
                <div
                  class="donation-page__text title--section color--basic donate-form--smaller-text"
                  v-html="model.giftAid.subtitle"
                ></div>
                <b-form-checkbox name="giftAid" v-model="state.giftAid">
                  <span class="donate-form--smaller-text">{{
                    model.giftAid.consent
                  }}</span>
                </b-form-checkbox>
                <div
                  class="donation-page__text donate-form--smaller-text"
                  v-html="model.giftAid.text"
                ></div>
                <div
                  class="text--explanation"
                  v-html="model.giftAid.explanatory"
                ></div>
              </div>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12">
              <h5 class="title--section color--pepper">
                {{ model.keepInTouch.title }}
              </h5>
            </b-col>
          </b-row>
          <b-row v-if="model.keepInTouch.checkboxDescription">
            <b-col cols="12">
              <b-form-checkbox
                class="custom-checkbox-inline"
                :v-model="state.contact.selectedContactCheckbox"
                @change="setContactCheckbox"
              >
              </b-form-checkbox>
              <div
                class="d-inline-block width90"
                v-html="model.keepInTouch.checkboxDescription"
              ></div>
            </b-col>
          </b-row>
          <b-row v-if="model.keepInTouch.hospiceCheckboxDescription">
            <b-col cols="12">
              <b-form-checkbox
                class="custom-checkbox-inline"
                :v-model="state.contact.selectedHospiceContactCheckbox"
                @change="setHospiceContactCheckbox"
              >
              </b-form-checkbox>
              <div
                class="d-inline-block width90"
                v-html="model.keepInTouch.hospiceCheckboxDescription"
              ></div>
            </b-col>
          </b-row>
          <b-row v-if="model.privacyPolicy" class="donate-form--smaller-text">
            <b-col cols="12">
              <span
                class="mr-1"
                v-text="model.translations['donation.Form.PrivacyPolicy']"
              ></span>
              <strong>
                <a
                  :href="model.privacyPolicy.url"
                  :target="model.privacyPolicy.target"
                  v-text="model.privacyPolicy.text"
                ></a>
              </strong>
            </b-col>
          </b-row>
          <b-row class="pt-3" v-if="model.keepInTouch.leadSources.length">
            <b-col cols="12">
              <p class="donation-page__text donate-form--smaller-text">
                {{ model.keepInTouch.leadSourceTitle }}
              </p>
            </b-col>
            <b-col cols="12" lg="6">
              <div>
                <b-form-select
                  name="leadSource"
                  class="w-100"
                  v-model="state.contact.selectedLeadSource"
                  :options="model.keepInTouch.leadSources"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>
                      {{ model.translations["generic.Select"] }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12">
              <h5 class="title--section color--pepper">
                {{
                  model.translations[
                    "registerTree.Form.DonationsPaymentMethodTitle"
                  ]
                }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div
                class="payment"
                :class="{ 'is-invalid': $v.state.paymentMethodId.$error }"
              >
                <div
                  class="payment-method"
                  @click="setPaymentMethod(method.id)"
                  :class="{ active: state.paymentMethodId === method.id }"
                  v-for="method in paymentMethods"
                  :key="method.Id"
                >
                  <img :alt="method.text" :src="method.mediaUrl" />
                  <label v-text="method.text"></label>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div
                class="invalid-feedback"
                v-if="genericErrorMessage"
                v-text="genericErrorMessage"
              ></div>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="12" lg="6" class="text-center">
              <b-button type="submit" variant="primary" class="button--large"
                >{{ model.translations["donation.Form.Button"] }}
              </b-button>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="12" lg="6" class="text-center">
              <div
                class="donation-page__text donate-form--smaller-text"
                v-if="model.termsAndConditions"
              >
                <b-link
                  class="donation-page--link"
                  :target="model.termsAndConditions.target"
                  :href="model.termsAndConditions.url"
                  >{{ model.termsAndConditions.text }}
                </b-link>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <b-alert
      v-model="hasValidationError"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000; text-align: center;"
      variant="danger"
      dismissible
    >
      {{ model.translations["donation.Form.ValidationErrorMessage"] }}
    </b-alert>
  </div>
</template>
<script>
import axios from "axios";
import { load } from "recaptcha-v3";
import ValidationMixin from "@/mixins/validationMixin";
import {
  required,
  email,
  sameAs,
  requiredIf,
  maxLength
} from "vuelidate/lib/validators";
import { nameFieldMaxLength } from "@/domain/constants";
import { BAlert } from "bootstrap-vue";
import { isValidPostCode, nameField } from "@/domain/validationRules";
import ExperianService from "@/domain/services/experianService";

export default {
  name: "DonateForm",
  mixins: [ValidationMixin],
  components: {
    BAlert
  },
  mounted() {
    this.maxNameLength = nameFieldMaxLength;
    this.$setBuisy(true);
    load(this.model.reCaptcha.siteKey).then(recaptcha => {
      this.recaptcha = recaptcha;
      this.$setBuisy(false);
    });
    this.$setBuisy(true);
    axios({
      method: "GET",
      url: this.model.initializePaymentUrl
    })
      .then(result => {
        this.paymentMethods = result.data.paymentMethods;
      })
      .catch(error => {
        throw error;
      })
      .then(() => {
        this.$setBuisy(false);
      });
    this.state.countryId = this.model.giftAidCountryId;
    this.showGiftAid = true;
    this.prepopulateCustomer();
    if (this.model.donationTypes.length == 1) {
      this.hideDonationTypes = true;
      this.state.donationType = this.model.donationTypes[0].value;
    }
    this.state.source = this.model.campaignSource;
  },
  data() {
    return {
      maxNameLength: null,
      showHospice: false,
      showGiftAid: false,
      paymentMethods: Array,
      genericErrorMessage: "",
      hideDonationTypes: false,
      hasValidationError: false,
      state: {
        donationType: null,
        amount: null,
        hospiceId: null,
        message: null,
        title: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        emailConfirmation: null,
        addressLine1: null,
        addressLine2: null,
        locality: null,
        postCode: null,
        countryId: null,
        giftAid: false,
        paymentMethodId: null,
        contact: {
          selectedContactCheckbox: false,
          selectedHospiceContactCheckbox: false,
          selectedLeadSource: null,
          donationType: ""
        },
        source: null
      }
    };
  },
  validations: {
    state: {
      donationType: { required },
      amount: { required },
      hospiceId: {
        required: requiredIf(function() {
          return this.showHospice;
        })
      },
      title: { required },
      firstName: {
        required,
        maxLength: maxLength(nameFieldMaxLength),
        nameField
      },
      lastName: {
        required,
        maxLength: maxLength(nameFieldMaxLength),
        nameField
      },
      email: { required, email },
      emailConfirmation: { required, email, sameAsEmail: sameAs("email") },
      phone: { required },
      postCode: {
        required,
        isCorrect(value) {
          if (value === "" || !this.showGiftAid) return true;

          if (!isValidPostCode(value)) {
            return false;
          }
          return new Promise((resolve, reject) => {
            ExperianService.getAddressesByPostCode(value)
              .then(response => {
                resolve(response.data.results.length > 0);
              })
              .catch(() => reject(false));
          });
        }
      },
      addressLine1: { required },
      locality: { required },
      countryId: { required },
      paymentMethodId: { required }
    }
  },
  methods: {
    prepopulateCustomer() {
      if (!this.model.customer) {
        return;
      }
      this.state.title = this.model.customer.attention;
      this.state.firstName = this.model.customer.firstName;
      this.state.lastName = this.model.customer.lastName;
      this.state.addressLine2 = this.model.customer.address2;
      this.state.locality = this.model.customer.city;
      this.state.postCode = this.model.customer.postCode;
      this.state.countryId = this.model.customer.countryId;
    },
    changeType(event) {
      this.showHospice = event === "Hospice";
    },
    changeCountry(event) {
      this.showGiftAid = event == this.model.giftAidCountryId;
      this.state.giftAid = false;
    },
    setPaymentMethod(event) {
      if (this.state.paymentMethodId === event)
        this.state.paymentMethodId = null;
      else this.state.paymentMethodId = event;
    },
    setContactCheckbox(val) {
      this.state.contact.selectedContactCheckbox = val;
    },
    setHospiceContactCheckbox(val) {
      this.state.contact.selectedHospiceContactCheckbox = val;
    },
    scrollToFirstInvalidElement() {
      let element = this.$el.getElementsByClassName("is-invalid")[0];
      if (element != undefined) {
        this.$scrollTo(element, 200, { offset: -80 });
      }
    },
    setAmount(amount) {
      this.state.amount = amount;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.$nextTick(() => this.scrollToFirstInvalidElement());
        this.hasValidationError = true;
        return;
      }
      this.$setBuisy(true);
      this.recaptcha.execute("register").then(token => {
        axios({
          method: "POST",
          url: this.model.submitFormUrl,
          data: { ...this.state, captchaToken: token }
        })
          .then(response => {
            window.location.href = response.data.paymentUrl;
          })
          .catch(() => {
            this.genericErrorMessage = this.model.translations["generic.Error"];
            this.$setBuisy(false);
          });
      });
    }
  },
  props: {
    model: Object
  }
};
</script>
<style lang="scss">
@import "~@/scss/_variables.scss";
@import "~@/scss/_breakpoints.scss";
@import "~@/components/Payment.scss";

.donate-form {
  &--smaller-text {
    font-size: 0.875rem;
  }

  &__predefined-donations {
    text-align: center;

    span {
      height: 50px;
      padding: 12px 16px;
      width: 100%;

      @include media-breakpoint-up(lg) {
        min-width: 80px;
      }
    }

    span:last-child {
      margin-right: 0 !important;
    }

    &__item {
      @include media-breakpoint-down(sm) {
        padding-top: 1rem;
      }
    }
  }

  .col-12 {
    @include media-breakpoint-down(md) {
      padding-bottom: 1rem;
    }
  }

  .row {
    @include media-breakpoint-up(lg) {
      padding-bottom: 1rem;
    }
  }

  hr {
    margin-bottom: 1rem;
  }

  .data-collection-donation__payment {
    margin-top: initial;
  }
}
</style>