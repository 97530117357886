<template>
  <section class="tree-registration-form padding--text-content--small">
    <b-modal hide-footer ref="invalidDistanceModal">
      <template v-slot:modal-title>
        {{
            model.dataCollectionLocation.translations[
            "registerTree.Form.Location.InvalidDistance.Header"
            ]
        }}
      </template>
      <div class="d-block text-center">
        <h5>
          {{
              model.dataCollectionLocation.translations[
              "registerTree.Form.Location.InvalidDistance.Body"
              ]
          }}
        </h5>
      </div>
      <b-button class="mt-3 btn-primary" variant="primary" block @click="$refs['invalidDistanceModal'].hide()">
        {{
            model.dataCollectionLocation.translations[
            "registerTree.Form.Location.InvalidDistance.Button"
            ]
        }}
      </b-button>
    </b-modal>
    <b-form @submit.stop.prevent="submit">
      <div class="section-hospice-intro">
        <b-row align-h="center">
          <b-col cols="12" class="mb-2 mb-lg-3">
            <b-row class="justify-content-md-center">
              <b-col align-self="center" cols="12" :lg="currentHospice.logo ? 8 : 12" class="mb-2 mb-lg-3">
                <h5 class="title--list color--pepper">
                  {{
                      model.translations["registerTree.ChristmasCollectionPrefix"]
                  }}
                  {{ currentHospice.name }}
                </h5>
              </b-col>
              <b-col v-if="currentHospice.logo" cols="12" md="6" lg="4" class="text-center mb-2 mb-lg-3">
                <b-img :src="currentHospice.logo" :alt="currentHospice.name" class="hero-section__logo"></b-img>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="auto">
                <p class="font--normal" v-html="currentHospice.description"></p>
                <p class="font--normal">
                  {{
                      model.translations[
                      "registerTree.ChristmasCollectionGreatNews"
                      ]
                  }}
                  <span class="text-primary font--semibold">{{ toLocalDate(currentHospice.collectionDateStart) }} -
                    {{ toLocalDate(currentHospice.collectionDateEnd) }}</span>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <hr />
      <div class="section-location">
        <b-row>
          <b-col cols="12" class="mb-2 mb-lg-3">
            <h5 class="title--section color--pepper">
              {{ model.dataCollectionLocation.title }}
            </h5>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="12" lg="6" class="mb-2 mb-lg-3">
            <b-row align-v="center">
              <b-col cols="8" class="mb-2 mb-lg-3">
                <b-form-input class="form-control font--semibold title--section" v-model="postCode"
                  :state="postcodeValidationState" disabled></b-form-input>
                  <b-form-invalid-feedback :state="postcodeValidationState">{{
                    model.dataCollectionLocation.translations[
                    "registerTree.Form.PostCodeSeemsInvalid"
                    ]
                }}
                </b-form-invalid-feedback>
              </b-col>
              <b-col cols="4" class="mb-2 mb-lg-3">
                <span class="change-postcode font--smaller">
                  <a href="#" v-scroll-to="'#register-tree-postcode'">{{
                      model.dataCollectionLocation.translations["generic.Change"]
                  }}</a></span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" v-if="foundAddressInExperian" class="mb-2 mb-lg-3">
                <b-form-select v-model="$v.addresses.selected.$model" :state="validationState($v.addresses.selected)"
                  :options="addresses.options" @change="deliveryAddressChanged"></b-form-select>
                <b-form-invalid-feedback :state="validationState($v.addresses.selected)">{{
                    model.translations["generic.FieldRequired"]
                }}
                </b-form-invalid-feedback>
              </b-col>
              <b-col cols="12" class="mb-2 mb-lg-3" v-if="!foundAddressInExperian || addressSelectedOnDropdown">
                <b-form-input class="mb-2 mb-lg-3" :disabled="true"
                  v-model="$v.state.shippingDetails.addressLine1.$model" :state="
                    validationState($v.state.shippingDetails.addressLine1)
                  " :placeholder="
    model.dataCollectionLocation.translations[
    'generic.Address.AddressLine1'
    ]
" />
                <b-form-invalid-feedback :state="
                  validationState($v.state.shippingDetails.addressLine1)
                ">{{
    model.dataCollectionLocation.translations[
    "generic.FieldRequired"
    ]
}}
                </b-form-invalid-feedback>
                <b-form-input class="mb-2 mb-lg-3" :disabled="true"
                  v-model="state.shippingDetails.addressLine2" :placeholder="
                    model.dataCollectionLocation.translations[
                    'generic.Address.AddressLine2'
                    ]
                  " />
                <b-form-input class="mb-2 mb-lg-3" :disabled="true"
                  v-model="state.shippingDetails.addressLine3" :placeholder="
                    model.dataCollectionLocation.translations[
                    'generic.Address.AddressLine3'
                    ]
                  " />
                <b-form-input class="mb-2 mb-lg-3" v-model="$v.state.shippingDetails.locality.$model"
                  :disabled="true" :state="validationState($v.state.shippingDetails.locality)"
                  :placeholder="
                    model.dataCollectionLocation.translations[
                    'generic.Address.Locality'
                    ]
                  " />

                <div class="pb-3" v-text="
                  model.dataCollectionLocation.translations[
                  'registerTree.Form.Location.FreetextExplanatoryText'
                  ]
                "></div>
                <b-form-invalid-feedback :state="validationState($v.state.shippingDetails.locality)">{{
                    model.dataCollectionLocation.translations[
                    "generic.FieldRequired"
                    ]
                }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="6" class="mb-2 mb-lg-3">
            <div>
              <div class="pb-3" v-if="marker !== undefined" v-text="
                model.dataCollectionLocation.translations[
                'registerTree.Form.Location.MapExplanatoryText'
                ]
              "></div>
              <gmap-map :center="mapCenter" :zoom="mapZoom" :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: false,
                disableDefaultUi: false
              }" map-type-id="roadmap" style="width: 100%; height: 280px">
                <span v-if="marker">
                  <gmap-marker :position="marker" :clickable="false" :draggable="true" @dragend="markerDropped" />
                </span>
              </gmap-map>
            </div>
          </b-col>
        </b-row>
      </div>
      <hr />
      <div class="section-personal-details">
        <b-row>
          <b-col cols="12" class="mb-2 mb-lg-3">
            <h5 class="title--section color--pepper">
              {{ model.dataCollectionPersonalDetails.title }}
            </h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="2" class="mb-2 mb-lg-3">
            <b-form-select name="title" v-model="$v.state.personalDetails.title.$model"
              :options="model.dataCollectionPersonalDetails.titles"
              :state="validationState($v.state.personalDetails.title)">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>{{
                    model.dataCollectionPersonalDetails.translations[
                    "generic.PersonalDetails.Title"
                    ]
                }}
                </b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.title.$dirty &&
              !$v.state.personalDetails.title.required
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.SelectAtLeastOneOption"
    ]
}}
            </b-form-invalid-feedback>
          </b-col>
          <b-col cols="12" lg="5" class="mb-2 mb-lg-3">
            <b-form-input name="firstName" v-model="$v.state.personalDetails.firstName.$model" :placeholder="
              model.dataCollectionPersonalDetails.translations[
              'generic.PersonalDetails.FirstName'
              ]
            " :max="maxNameLength" :state="validationState($v.state.personalDetails.firstName)"></b-form-input>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.firstName.$dirty &&
              !$v.state.personalDetails.firstName.required
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.FieldRequired"
    ]
}}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.firstName.$dirty &&
              !$v.state.personalDetails.firstName.maxLength
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.ValueTooLong"
    ]
}}
              {{ maxNameLength }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.firstName.$dirty &&
              !$v.state.personalDetails.firstName.nameField
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.IllegalCharacters"
    ]
}}
            </b-form-invalid-feedback>
          </b-col>
          <b-col cols="12" lg="5" class="mb-2 mb-lg-3">
            <b-form-input name="lastName" v-model="$v.state.personalDetails.lastName.$model" :placeholder="
              model.dataCollectionPersonalDetails.translations[
              'generic.PersonalDetails.LastName'
              ]
            " :max="maxNameLength" :state="validationState($v.state.personalDetails.lastName)"></b-form-input>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.lastName.$dirty &&
              !$v.state.personalDetails.lastName.required
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.FieldRequired"
    ]
}}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.lastName.$dirty &&
              !$v.state.personalDetails.lastName.maxLength
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.ValueTooLong"
    ]
}}
              {{ maxNameLength }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.lastName.$dirty &&
              !$v.state.personalDetails.lastName.nameField
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.IllegalCharacters"
    ]
}}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6" class="mb-2 mb-lg-3">
            <vue-tel-input name="phone" v-model="$v.state.personalDetails.phone.$model" :placeholder="
              model.dataCollectionPersonalDetails.translations[
              'generic.PersonalDetails.PhoneNumber'
              ]
            " :wrapper-classes="{
  'is-invalid':
    validationState($v.state.personalDetails.phone) === false,
  'custom-phone': true
}"></vue-tel-input>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.phone.$dirty &&
              !$v.state.personalDetails.phone.required
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.FieldRequired"
    ]
}}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6" class="mb-2 mb-lg-3">
            <b-form-input name="email" type="email" @focus="unsetReadOnly()" :readonly="emailReadOnly"
              @click="$event.target.blur(); $event.target.focus();" autocomplete="off"
              v-model="$v.state.personalDetails.email.$model" :placeholder="
                model.dataCollectionPersonalDetails.translations[
                'generic.PersonalDetails.Email'
                ]
              " :state="
  validateEmail(
    $v.state.personalDetails.email,
    $v.state.personalDetails.emailConfirmation
  )
"></b-form-input>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.email.$dirty &&
              !$v.state.personalDetails.email.required
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.FieldRequired"
    ]
}}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.email.$dirty &&
              !$v.state.personalDetails.email.email
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.FieldEmailInvalid"
    ]
}}
            </b-form-invalid-feedback>
          </b-col>
          <b-col cols="12" lg="6" class="mb-2 mb-lg-3">
            <b-form-input name="repeatEmail" type="email" @focus="unsetReadOnly()" :readonly="emailReadOnly"
              @click="$event.target.blur(); $event.target.focus();" autocomplete="off"
              v-model="$v.state.personalDetails.emailConfirmation.$model" :placeholder="
                model.dataCollectionPersonalDetails.translations[
                'generic.PersonalDetails.EmailConfirmation'
                ]
              " :state="
  validationState($v.state.personalDetails.emailConfirmation)
"></b-form-input>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.emailConfirmation.$dirty &&
              !$v.state.personalDetails.emailConfirmation.required
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.FieldRequired"
    ]
}}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="
              $v.state.personalDetails.emailConfirmation.$dirty &&
              !$v.state.personalDetails.emailConfirmation.sameAsEmail
            ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.PersonalDetails.EmailsNotMatch"
    ]
}}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </div>
      <hr />
      <div class="section-trees">
        <b-row>
          <b-col cols="12" class="mb-2 mb-lg-3">
            <h5 class="title--section color--pepper">
              {{ model.trees.title }}
            </h5>
          </b-col>
        </b-row>


        <b-row>
          <b-col cols="12" lg="6" class="mb-2 mb-lg-3">

            <b-form-select
              v-if="!otherNumberOfTrees"
              :options="treesOptions" v-model="treesFormState.numberOfTrees"
              :state="validationState($v.state.itemsToCollect.products)" autofocus>
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>{{
                    model.trees.translations["registerTree.Trees.NumberOfTrees"]
                }}
                </b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback v-if="!otherNumberOfTrees"
              :state="validationState($v.state.itemsToCollect.products)">{{
                  model.trees.translations["generic.SelectAtLeastOneOption"]
              }}
            </b-form-invalid-feedback>

            <b-form-invalid-feedback v-if="otherNumberOfTrees"
              :state="validationState($v.state.itemsToCollect.products)">{{
                  model.trees.translations["generic.FieldRequired"]
              }}
            </b-form-invalid-feedback>

            <b-form-invalid-feedback v-if="otherNumberOfTrees" :state="
              treesFormState.numberOfTrees > 0 &&
              $v.treesFormState.numberOfTrees.$dirty &&
              !$v.treesFormState.numberOfTrees.maxLength
            ">{{ model.trees.translations["registerTree.Trees.ToManyTrees"] }}
            </b-form-invalid-feedback>

            <b-form-checkbox v-if="!selectedManyProducts" class="data-collection__checkbox"
              v-model="treesFormState.isBigTree">{{ model.trees.translations["registerTree.Trees.SizeOfTree"] }}
            </b-form-checkbox>

            <b-form-checkbox v-else class="data-collection__checkbox" v-model="treesFormState.isBigTree">{{
                model.trees.translations["registerTree.Trees.SizeOfTreePlular"]
            }}
            </b-form-checkbox>

          </b-col>
          <b-col cols="12" lg="6" class="mb-2 mb-lg-3">
            <b-form-textarea maxlength="254" v-model="state.itemsToCollect.otherDetails" rows="3" :placeholder="
              model.trees.translations['registerTree.Trees.OtherDetails']
            " @paste.prevent v-on:keypress="isValidInput($event)"></b-form-textarea>
          </b-col>
        </b-row>
      </div>
      <hr />
      <div class="section-keep-in-touch">
        <b-row>
          <b-col cols="12" class="mb-2 mb-lg-3">
            <h5 class="title--section color--pepper">
              {{ model.keepInTouch.keepInTouchData.title }}
            </h5>
          </b-col>
          <b-col cols="12" class="mb-2 mb-lg-3">
            <div class="mr-1 font--smaller color--pepper" v-html="model.keepInTouch.keepInTouchData.description"></div>
          </b-col>
        </b-row>
        <b-row v-if="model.keepInTouch.keepInTouchData.checkboxDescription">
          <b-col cols="12">
            <b-form-checkbox class="custom-checkbox-inline" :v-model="state.contactMethods.selectedContactCheckbox"
              @change="setContactCheckbox">
            </b-form-checkbox>
            <div class="d-inline-block width90" v-html="model.keepInTouch.keepInTouchData.checkboxDescription"></div>
          </b-col>
        </b-row>
        <b-row v-if="model.keepInTouch.keepInTouchData.hospiceCheckboxDescription">
          <b-col cols="12">
            <b-form-checkbox class="custom-checkbox-inline"
              :v-model="state.contactMethods.selectedHospiceContactCheckbox" @change="setHospiceContactCheckbox">
            </b-form-checkbox>
            <div class="d-inline-block width90" v-html="model.keepInTouch.keepInTouchData.hospiceCheckboxDescription">
            </div>
          </b-col>
        </b-row>
        <b-row class="pt-3" v-if="model.keepInTouch.keepInTouchData.leadSources.length">
          <b-col cols="12" class="mb-2 mb-lg-3">
            <p class="donation-page__text donate-form--smaller-text">
              {{ model.keepInTouch.keepInTouchData.leadSourceTitle }}
            </p>
          </b-col>
          <b-col cols="12" lg="6" class="mb-2 mb-lg-3">
            <div>
              <b-form-select name="leadSource" class="w-100" v-model="$v.state.contactMethods.leadSource.$model"
                :options="model.keepInTouch.keepInTouchData.leadSources"
                :state="validationState($v.state.contactMethods.leadSource)"
                @change="$event => setLeadSource($event)">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>
                    {{ model.keepInTouch.translations["generic.Select"] }}
                  </b-form-select-option>
                </template>
              </b-form-select>


              <b-form-invalid-feedback v-if="
                $v.state.contactMethods.leadSource.$dirty &&
                !$v.state.contactMethods.leadSource.required
              ">{{
    model.dataCollectionPersonalDetails.translations[
    "generic.SelectAtLeastOneOption"
    ]
}}
              </b-form-invalid-feedback>



            </div>
          </b-col>
        </b-row>
      </div>
      <hr />
      <div class="section-donation">
        <b-row>
          <b-col cols="12" class="mb-2 mb-lg-3">
            <h5 class="title--section color--pepper">
              {{ model.donation.title }}
            </h5>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col order-lg="1" v-if="
            currentHospice.donationAmounts && currentHospice.donationAmounts
          " lg="6" cols="12" class="mb-2 mb-lg-3">
            <div class="donate-form__predefined-donations">
              <b-row class="justify-content-start">
                <b-col class="donate-form__predefined-donations__item mb-2" cols="6" sm="3"
                  @click="setAmount(item.value)" :key="index" v-for="(item, index) in currentHospice.donationAmounts">
                  <span class="btn btn-success px-lg-0">&pound; {{ item.value }}</span>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col :lg="
            currentHospice.donationAmounts &&
              currentHospice.donationAmounts.length
              ? 6
              : 12
          " cols="12" class="mb-2 mb-lg-3">
            <currency-input class="w-100 form-control" name="amount" :class="{
              'is-invalid':
                $v.state.payment.prize.$invalid &&
                $v.state.payment.prize.$dirty
            }" v-model="$v.state.payment.prize.$model" currency="GBP" locale="en-GB" :placeholder="'Amount'"
              :allow-negative="false" :precision="2" :value-range="{ min: 0, max: 9999 }"></currency-input>
            <b-form-invalid-feedback v-if="
              $v.state.payment.prize.$dirty &&
              !$v.state.payment.prize.required
            ">{{ model.translations["generic.FieldRequired"] }}
            </b-form-invalid-feedback>
            <p class="font--smaller pt-3" v-if="state.payment.prize == null || state.payment.prize === 0" v-text="
              model.donation.translations[
              'registerTree.Form.DonationsDonateNothingText'
              ]
            "></p>
          </b-col>
        </b-row>
      </div>
      <hr v-if="state.payment.prize != null && state.payment.prize != 0" />
      <div class="section-gift-aid" v-if="state.payment.prize != null && state.payment.prize != 0">
        <b-row align-h="center">
          <b-col cols="12" md="11" class="shadow-box padding--text-content--small mb-2 mb-lg-3 mt-2 mt-lg-3">
            <div class="donate-form__gift-aid">
              <h5 class="title--list color--pepper">
                {{ model.donation.giftAid.title }}
              </h5>
              <div class="donation-page__text title--section color--basic donate-form--smaller-text"
                v-html="model.donation.giftAid.subtitle"></div>
              <b-form-checkbox name="giftAid" v-model="state.payment.giftAid">
                <span class="donate-form--smaller-text">{{
                    model.donation.giftAid.consent
                }}</span>
              </b-form-checkbox>
              <div class="donation-page__text donate-form--smaller-text" v-html="model.donation.giftAid.text"></div>
              <div class="text--explanation" v-html="model.donation.giftAid.explanatory"></div>
            </div>
          </b-col>
        </b-row>
      </div>
      <hr v-show="state.payment.prize" />
      <transition name="fade">
        <div v-show="state.payment.prize" class="section-payment">
          <b-row>
            <b-col cols="12" class="mb-2 mb-lg-3">
              <h5 class="title--section color--pepper">
                {{
                    model.donation.translations[
                    "registerTree.Form.DonationsPaymentMethodTitle"
                    ]
                }}
              </h5>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col cols="12" class="mb-2 mb-lg-3">
              <div class="payment mt-0 text-center" :class="{ 'is-invalid': $v.state.payment.paymentMethodId.$error }">
                <div class="payment-method" @click="setPaymentMethod(method)" :class="{
                  active: state.payment.paymentMethodId === method.id
                }" v-for="method in paymentMethods" :key="method.id">
                  <img :alt="method.text" :src="method.mediaUrl" />
                  <label v-text="method.text"></label>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" lg="9" class="pb-3">
              <div class="font--smaller" v-html="model.donation.noRefundsDescription"></div>
            </b-col>
          </b-row>
        </div>
      </transition>
      <hr />
      <transition name="fade">
        <div class="section-payment">
          <b-row>
            <b-col cols="12">
              <b-form-checkbox class="data-collection__checkbox" :class="{
                'is-invalid':
                  $v.state.contactMethods.selectedTandCCheckbox.$invalid &&
                  $v.state.contactMethods.selectedTandCCheckbox.$dirty
              }" :v-model="state.contactMethods.selectedTandCCheckbox" @change="setTandCCheckbox">
                <span v-html="model.translations['registerTree.Form.TermsAndConditions']"></span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="text-center mt-3">
              <b-button type="submit" variant="primary" class="noWrap button--large">{{ submitButtonText }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </transition>

    </b-form>
    <b-alert :show="geoCoderErrorTime" dismissible class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000; text-align: center;" variant="warning">
      {{ model.translations["registerTree.Form.GeoCoderError"] }}
    </b-alert>
  </section>
</template>

<script>
import { longLocalDate, scrollOptions, shortLocalDate } from "@/domain/utils";
import ValidationMixin from "@/mixins/validationMixin";
import { nameFieldMaxLength } from "@/domain/constants";
import {
  dataCollectionLocation,
  dataCollectionContactDetails,
  dataCollectionContactMethods,
  collectTrees
} from "@/domain/validationRules";
import { requiredIf } from "vuelidate/lib/validators";
import ExperianService from "@/domain/services/experianService";
import googleGeoCoder from "@/domain/services/googleGeoCoder";
import axios from "axios";
import { required, maxValue } from "vuelidate/lib/validators";
import { load } from "recaptcha-v3";
import { BAlert } from "bootstrap-vue";

let maximumNumberOfTrees = 3;

export default {
  name: "RegistrationOpenForm",
  mixins: [ValidationMixin],
  components: { BAlert },
  props: {
    model: Object,
    hospices: Array,
    postCode: String
  },
  mounted() {
    this.state.payment.prize = this.$store.ConfigStore.getters.defaultDonationValue;
    this.fetchAddresses();
    this.fetchPaymentMethods();
    this.maxNameLength = nameFieldMaxLength;
    this.$scrollTo(this.$el, 200, scrollOptions);
    this.$setBuisy(false);

    load(this.model.summary.reCaptcha.siteKey).then(recaptcha => {
      this.recaptcha = recaptcha;
    });
  },
  data() {
    return {
      emailReadOnly: true,
      maxNameLength: null,
      geoCoderErrorTime: 0,
      geoCoderError: false,
      initialCoordinates: undefined,
      marker: undefined,
      otherNumberOfTrees: false,
      manualFlowInitialized: false,
      markerDragged: false,
      ukMapCenter: { lng: -2.479169, lat: 54.432394 },
      addresses: {
        options: [],
        selected: null
      },
      paymentMethods: [],
      state: {
        shippingDetails: {
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          locality: "",
          coordinates: {
            lon: 0,
            lat: 0
          }
        },
        itemsToCollect: {
          products: [],
          otherDetails: ""
        },
        personalDetails: {
          title: null,
          firstName: "",
          lastName: "",
          email: "",
          emailConfirmation: "",
          phone: ""
        },
        payment: {
          prize: null,
          paymentMethodId: null,
          paymentProviderName: null,
          giftAid: false
        },
        contactMethods: {
          selectedContactCheckbox: false,
          selectedHospiceContactCheckbox: false,
          selectedTandCCheckbox: false,
          leadSource: null
        },
        captchaToken: null
      },
      treesFormState: {
        numberOfTrees: null,
        isBigTree: false
      }
    };
  },
  validations() {
    let v = {
      addresses: {
        selected: {
          required: requiredIf(function () {
            return this.foundAddressInExperian;
          })
        }
      },
      state: {
        shippingDetails: dataCollectionLocation,
        personalDetails: dataCollectionContactDetails,
        contactMethods: dataCollectionContactMethods,
        itemsToCollect: collectTrees,
        payment: {
          prize: {
            required,
            greaterThanZeroIf: function (value, vm) {
              return vm.paymentMethodId ? value > 0 : true;
            }
          },
          paymentMethodId: {
            required: requiredIf(function () {
              return (
                this.state.payment.prize && this.state.payment.prize !== "0"
              );
            })
          }
        }
      },
      treesFormState: {
        numberOfTrees: {
          required
        }
      }
    };
    if (this.currentHospice.limit) {
      v.treesFormState.numberOfTrees = {
        required,
        maxValue: maxValue(this.currentHospice.limit)
      };
    }
    return v;
  },
  computed: {
    currentHospice() {
      return this.hospices[0];
    },
    mapCenter() {
      if (this.initialCoordinates) return this.initialCoordinates;
      return this.ukMapCenter;
    },
    mapZoom() {
      if (this.initialCoordinates) return 17;
      return 5;
    },
    foundAddressInExperian() {
      return this.addresses.options.length > 1;
    },
    mapSearchQuery() {
      return [this.state.shippingDetails.addressLine1]
        .filter(Boolean)
        .join(", ");
    },
    addressSelectedOnDropdown() {
      return this.addresses.selected !== null;
    },
    selectedManyProducts() {
      if (!this.treesFormState.numberOfTrees) {
        return false;
      }
      return parseInt(this.treesFormState.numberOfTrees) > 1;
    },
    treesOptions: function () {
      let listItems = [];
      let length =
        (this.currentHospice.limit ?? maximumNumberOfTrees) >= maximumNumberOfTrees ? maximumNumberOfTrees : this.currentHospice.limit;
        
      for (let i = 1; i <= length; i++) {
        listItems.push({ value: i, text: i });
      }
      return listItems;
    },
    submitButtonText: function () {
      return this.state.payment.prize ?? false
        ? this.model.summary.translations["generic.ProceedToCheckout.Donate"]
        : this.model.summary.translations[
        "generic.ProceedToCheckout.RegisterTree"
        ];
    },
    postcodeValidationState: function () {   
      return this.foundAddressInExperian;
    }
  },
  methods: {
    isValidInput(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]*$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text 
    },
    setLeadSource(val) {
      this.state.contactMethods.leadSource = val;
    },
    isStringNullOrWhiteSpace(str) {
      return str === undefined || str === null
        || typeof str !== 'string'
        || str.match(/^ *$/) !== null;
    },
    unsetReadOnly() {
      const self = this;
      self.emailReadOnly = false;
    },
    toLocalDate(date) {
      return longLocalDate(this.$store.ConfigStore.getters.culture, date);
    },
    toShortLocalDate(date) {
      return shortLocalDate(this.$store.ConfigStore.getters.culture, date);
    },
    fetchAddresses() {
      const self = this;
      this.$setBuisy(true);
      this.addresses = {
        options: [
          {
            value: null,
            text: this.model.dataCollectionLocation.translations[
              "generic.SelectAddress"
            ],
            disabled: true
          }
        ],
        selected: null
      };
      ExperianService.getAddressesByPostCode(this.postCode)
        .then(response => {
          // If not found in Expierian try to geolocate it in google
          if (!response.data.results.length) {
            googleGeoCoder
              .getAddressesCoordinates(
                self.$store.ConfigStore.getters.mapKey,
                `UNITED KINGDOM,${self.postCode}`,
                self.postCode
              )
              .then(coordinates => {
                self.initialCoordinates = coordinates;
                self.marker = coordinates;
                self.state.postcode = this.postCode;
              })
              .catch(() => {
                self.initializeManualAddressFlow();
              })
              .finally(() => this.$setBuisy(false));
          } 
          else 
          {
            response.data.results.forEach(element => {
              if(element.suggestion!=="")
              {
                var postcodeParts = element.suggestion.split(" ").slice(-2);
                var postCodeFromList = postcodeParts[0] + " " + postcodeParts[1];
                if(postCodeFromList===this.postCode)
                {
                  this.addresses.options.push({
                    value: element.format,
                    text: element.suggestion
                  });
                }
              }
            });
            this.$setBuisy(false);
          }
        })
        .catch(() => {
          googleGeoCoder
            .getAddressesCoordinates(
              self.$store.ConfigStore.getters.mapKey,
              `UNITED KINGDOM,${self.postCode}`,
              self.postCode
            )
            .then(coordinates => {
              self.initialCoordinates = coordinates;
              self.marker = coordinates;
              self.state.postcode = this.postCode;
            })
            .catch(() => {
              self.initializeManualAddressFlow();
            })
            .finally(() => self.$setBuisy(false));
        });
    },
    markerDropped(event) {
      this.marker = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      this.markerDragged = true;
      this.geoCoderErrorTime = 0;
    },
    deliveryAddressChanged: function (newVal) {
      var self = this;
      if (newVal) {
        self.$setBuisy(true);
        ExperianService.getDetails(newVal).then(function (address) {
          self.state.shippingDetails = { ...address };
          self.$setBuisy(false);
          googleGeoCoder
            .getAddressesCoordinates(
              self.$store.ConfigStore.getters.mapKey,
              self.mapSearchQuery,
              self.postCode
            )
            .then(coordinates => {
              self.initialCoordinates = coordinates;
              self.marker = coordinates;
            })
            .catch(() => {
              self.geoCoderErrorTime = 10;
              self.geoCoderError = true;
              googleGeoCoder
                .getAddressesCoordinates(
                  self.$store.ConfigStore.getters.mapKey,
                  self.postCode,
                  null
                )
                .then(coordinates => {
                  self.initialCoordinates = coordinates;
                  self.marker = coordinates;
                });
            });
        });
      }
    },
    fetchPaymentMethods: function () {
      let self = this;
      self.$setBuisy(true);
      axios({
        method: "GET",
        url: this.model.donation.initializePaymentUrl
      })
        .then(result => {
          self.paymentMethods = result.data.paymentMethods;
        })
        .catch(error => {
          throw error;
        })
        .then(() => {
          self.$setBuisy(false);
        });
    },
    setPaymentMethod(method) {
      this.$v.$reset();
      if (this.state.payment.paymentMethodId === method.id)
        this.state.payment.paymentMethodId = null;
      else {
        this.state.payment.paymentMethodId = method.id;
        this.state.payment.paymentProviderName = method.text;
      }
    },
    setAmount(amount) {
      this.state.payment.prize = amount * 1;
    },
    setContactCheckbox(val) {
      this.state.contactMethods.selectedContactCheckbox = val;
    },
    setHospiceContactCheckbox(val) {
      this.state.contactMethods.selectedHospiceContactCheckbox = val;
    },
    setTandCCheckbox(val) {
      this.state.contactMethods.selectedTandCCheckbox = val;
    },

    scrollToFirstInvalidElement() {
      let element = this.$el.getElementsByClassName("is-invalid")[0];
      if (element != undefined) {
        this.$scrollTo(element, 200, { offset: -80 });
      }
    },
    otherNumbersOfTreesChange() {
      this.state.itemsToCollect.products = [];
    },
    submit() {
      this.$v.$touch();

      if (this.state.contactMethods.selectedContactCheckbox.$invalid === true) {
        this.hasValidationError = true;
        return;
      }

      if (this.$v.$anyError) {
        this.$nextTick(() => this.scrollToFirstInvalidElement());
        this.hasValidationError = true;
        return;
      }
      if (!this.manualFlowInitialized && !this.geoCoderError) {
        let dist = googleGeoCoder.measureDistanceInMeters(
          this.initialCoordinates,
          this.marker
        );
        if (dist > this.model.dataCollectionLocation.maxDistance) {
          this.$refs["invalidDistanceModal"].show();
          return;
        }
      }
      if (this.postcodeValidationState === false) {
        this.$nextTick(() => this.scrollToFirstInvalidElement());
        this.hasValidationError = true;
        return;
      }
      const self = this;
      this.state.shippingDetails.coordinates = this.marker;
      if (!this.state.shippingDetails.postCode) {
        this.state.shippingDetails.postCode = this.postCode;
      }
      this.recaptcha.execute("register").then(token => {
        self.state.captchaToken = token;
        self.$setBuisy(true);
        axios({
          method: "POST",
          url: self.model.summary.createOrderUrl,
          data: {
            hospiceId: self.currentHospice.nodeId,
            shippingDetails: self.state.shippingDetails,
            itemsToCollect: self.state.itemsToCollect,
            personalDetails: self.state.personalDetails,
            payment: self.state.payment,
            contactMethods: self.state.contactMethods,
            captchaToken: self.state.captchaToken,
            isManualFlow: self.manualFlowInitialized
          }
        })
          .then(response => {
            window.location.href = response.data.paymentUrl;
          })
          .catch(() => {
            self.showGenericError = true;
            self.$setBuisy(false);
          });
      });
    },
    initializeManualAddressFlow: function () {
      this.manualFlowInitialized = true;
      this.marker = this.ukMapCenter;
      this.state.postcode = this.postCode;
      this.state.shippingDetails.postCode = this.postCode;
    }
  },
  watch: {
    "state.payment.prize": function (newValue) {
      if (!newValue) this.state.payment.paymentMethodId = null;
    },
    treesFormState: {
      handler: function (newState) {
        if (!this.state.payment.prize) {
          this.state.payment.paymentMethodId = null;
          this.state.payment.giftAid = false;
          this.state.payment.paymentProviderName = null;
        }

        this.state.itemsToCollect.products = [];

        if (newState.numberOfTrees) {
          if (newState.isBigTree) {
            this.state.itemsToCollect.products.push({
              sku: this.model.trees.bigTree.sku,
              amount: newState.numberOfTrees
            });
          } else {
            this.state.itemsToCollect.products.push({
              sku: this.model.trees.smallTree.sku,
              amount: newState.numberOfTrees
            });
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
@import "~@/components/TreeRegistration/RegisterTree.scss";
</style>
